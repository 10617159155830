<template>
  <!-- paymentPayables -->
  <en-drawer :model-value="modelValue" title="批量付款" direction="btt" size="70%" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :ref="setRef('form')" :rules="form.rules" :loading="form.loading" class="grid grid-cols-5 gap-6">
      <en-form-item label="客户" prop="payee">
        <select-maintain
          v-model="form.data.payee"
          :ajax="{
            action: 'GET /enocloud/common/customer',
            params: (params, value) => (params.payload = { name: value, rsexp: 'id,name' })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          :disabled="!!form.data.payee?.id && form.data.paymentPayables?.length > 0"
          remote
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="付款方式" prop="paymentMethod">
        <select-maintain
          v-model="form.data.paymentMethod"
          :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['PAMTMTD']) }"
          :props="{ label: 'name', value: 'name' }"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="付款时间">
        <en-date-picker v-model="form.data.businessDatetime" type="datetime" value-format="YYYY-MM-DDThh:mm:ss" class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="备注">
        <en-input v-model="form.data.comment"></en-input>
      </en-form-item>
    </en-form>

    <div class="flex items-center gap-4 text-sm">
      <span>应付款{{ total.payableAmount }}</span>
      <span>已付款{{ total.paidAmount }}</span>
      <span>结算金额{{ total.totalAmount }}</span>
    </div>
    <en-table :data="form.data.paymentPayables" :ref="setRef('form')" :loading="form.loading">
      <en-table-column label="操作">
        <template #default="{ $index }">
          <en-button type="primary" text @click="table.delete.click($index)">删除</en-button>
        </template>
      </en-table-column>
      <en-table-column label="相关单号" prop="payable.serialNo"> </en-table-column>
      <en-table-column label="业务类型" prop="payable.type.message"> </en-table-column>
      <en-table-column label="应付款" prop="payable.payableAmount">
        <template #default="{ row }: { row: EnocloudSettlementDefinitions['PaymentPayableDto'] }">
          <span v-if="row.payable?.type?.code === 'PUC'">{{ formatMoney(row.payable?.purchase?.totalAmount) }}</span>
        </template>
      </en-table-column>
      <en-table-column label="已付款" prop="payable.paidAmount"> </en-table-column>
      <en-table-column label="付款" prop="remainingAmount">
        <template #default="{ row }: { row: EnocloudSettlementDefinitions['PaymentPayableDto'] }">
          <en-input-number v-model="row.remainingAmount" :max="row.amount - row.paidAmount" :precision="2"></en-input-number>
        </template>
      </en-table-column>

      <en-table-column label="备注" prop="payable.comment"> </en-table-column>
    </en-table>

    <template #footer>
      <button-ajax :method="footer.paymentDetail.click">选择付款单据</button-ajax>
      <button-ajax :method="footer.receipt.click">付 款</button-ajax>
    </template>
  </en-drawer>

  <more-payment-detail
    v-model="morePaymentDetail.visible"
    :selectedData="form.data.paymentPayables"
    @confirm="morePaymentDetail.confirm"
    :payee-id="form.data.payee?.id"
  ></more-payment-detail>
</template>

<script lang="ts">
import MorePaymentDetail from '@settlement/components/more-payment-detail.vue'
import { calculator } from '@enocloud/utils'
import dayjs from 'dayjs'
import { chain } from 'lodash-es'

const formDataInit = (): EnocloudSettlementDefinitions['PaymentDto'] => {
  const data = { type: { code: 'PAM', message: '', type: '', description: '' }, businessDatetime: dayjs().format('YYYY-MM-DDTHH:mm:ss') } as any
  return data
}

export default factory({
  components: { MorePaymentDetail },
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudServiceDefinitions['ServiceReservationDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    receipt: (id: number | undefined) => ['number', 'undefinded'].includes(typeof id)
  },

  watch: {
    modelValue: {
      async handler(row: any) {
        if (!this.modelValue) {
          this.form.data = formDataInit()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        data: formDataInit(),
        ajax: {
          submit: {
            action: 'POST /enocloud/settlement/payment',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        rules: {
          paymentMethod: [{ required: true, message: '请选择付款方式' }],
          payee: [{ required: true, message: '请选择客户' }]
        }
      },
      table: {
        data: [],
        children: {
          payableAmount: {
            change(value: number) {}
          },
          amount: {
            change(row: EnocloudSettlementDefinitions['PaymentDto']) {
              this.total.payableAmount - this.total.paidAmount
            }
          }
        },

        delete: {
          click(index: number) {
            this.form.data.paymentPayables.splice(index, 1)
          }
        }
      },
      morePaymentDetail: {
        visible: false,
        async confirm(tabledata: EnocloudSettlementDefinitions['PayableQueryDto'][]) {
          this.form.data.paymentPayables = []
          for (const item of tabledata) {
            const payable = (await this.ajax('GET /enocloud/settlement/payable/:payableId', { paths: [item.id] })).data[0]
            this.form.data.paymentPayables.push({
              amount: 0,
              paymentAdvanceDeposit: 0,
              payable
            })
          }
        }
      },
      footer: {
        paymentDetail: {
          async click() {
            this.morePaymentDetail.payeeId = this.form.data.payee.id
            if (this.form.data.payee.length === 0 || this.form.data.paymentMethod.length === 0) return
            this.morePaymentDetail.visible = true
          }
        },
        receipt: {
          async click() {
            if (this.form.data.paymentPayables.length === 0) return
            this.form.submit()
          }
        }
      }
    }
  },
  computed: {
    total() {
      const payableAmount = this.form.data.paymentPayables?.reduce((payableAmount, item) => calculator.add(payableAmount, item.payableAmount), 0)
      const paidAmount = this.form.data.paymentPayables?.reduce((paidAmount, item) => calculator.add(paidAmount, item.paidAmount), 0)
      const amount = this.form.data.paymentPayables?.reduce((amount, item) => calculator.add(amount, item.amount), 0)
      const totalAmount = this.form.data.paymentPayables?.reduce((remainingAmount, item) => calculator.add(remainingAmount, item.remainingAmount), 0)
      return {
        payableAmount,
        paidAmount,
        amount,
        totalAmount
      }
    }
  }
})
</script>
